@keyframes slide {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  26% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.btn-transparent {
  background: transparent;
  appearance: none;
  border: 0;
  color: var(--yes-white);
}
