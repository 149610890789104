a {
  color: var(--yes-grey-700);
  text-decoration: none;

  &.header-link {
    display: inline-flex;
    font-size: 0.875rem;
    align-items: center;
    padding: 0 8px;
    border: 1px solid transparent;
    border-radius: 100vmax;
    cursor: pointer;

    &:hover {
      border-color: var(--yes-grey-50);
    }
  }
}
