:root {
  /*keep --yes-green as first color in this file to make auto-processing easier*/
  --yes-green-50: #cff0e8;
  --yes-green-100: #9be0d0;
  --yes-green-200: #5dc0a9;
  --yes-green-300: #0e9a86;
  --yes-green-400: #007d6b;
  --yes-green-500: #006758;
  --yes-green-600: #04594c;
  --yes-green-650: #044A40;
  --yes-green-700: #05463d;
  --yes-green-800: #01342d;
  --yes-green-900: #032a24;

  --yes-white: #fff;
  --yes-white-50: #efefef;
  --yes-white-60: rgba(255, 255, 255, 0.6);

  --yes-grey-50: #f0f0f0;
  --yes-grey-100: #e1e1e1;
  --yes-grey-200: #bbb;
  --yes-grey-300: #929292;
  --yes-grey-400: #7d7d7d;
  --yes-grey-500: #5c5c5c;
  --yes-grey-600: #545454;
  --yes-grey-700: #414141;
  --yes-grey-800: #333333;
  --yes-grey-900: #292929;

  --yes-pink-50: #ffe2ec;
  --yes-pink-100: #fcbfd5;
  --yes-pink-200: #ff89b3;
  --yes-pink-300: #f55991;
  --yes-pink-400: #f23579;
  --yes-pink-500: #d62363;
  --yes-pink-600: #bf2059;
  --yes-pink-700: #a31b4b;
  --yes-pink-800: #7a1438;
  --yes-pink-900: #5a0f2a;

  --yes-red: #cc0a0a;

  --yes-gold: #cfaa75;
}
