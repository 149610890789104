@use 'typography';

body {
  @media screen and (min-width: 37.5625em) {
    #CybotCookiebotDialog #CybotCookiebotDialogBody .CybotCookiebotScrollContainer {
      max-height: 21.5em;
    }
  }

  #CybotCookiebotDialog {
    border-radius: 4px;
    font-family: var(--yes-font-primary);
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
    text-transform: uppercase;
  }

  #CybotCookiebotDialog label {
    text-transform: uppercase;
  }

  #CybotCookiebotDialog h2 {
    text-transform: inherit;
  }

  #CybotCookiebotDialogFooter
    #CybotCookiebotDialogBodyLevelButtonCustomize
    .CybotCookiebotDialogArrow {
    display: none;
  }

  a#CybotCookiebotDialogPoweredbyCybot {
    display: none;
  }

  #CybotCookiebotDialog
    .CybotCookiebotDialogBodyLevelButtonSliderWrapper
    input:focus-visible
    + span,
  #CybotCookiebotDialog :focus-visible {
    outline: transparent;
  }

  #CybotCookiebotDialogDetailFooter {
    display: none;
  }

  #CybotCookiebotDialog.CybotCookiebotDialogActive + #CybotCookiebotDialogBodyUnderlay {
    opacity: 0.4;
    pointer-events: auto;
  }

  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    border: 2px solid var(--yes-green-500);
  }

  #CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #ccc;
  }

  #CybotCookiebotDialog {
    border-radius: 0;
  }

  #CybotCookiebotDialogDetailFooter {
    display: none;
  }

  #CybotCookiebotDialogPoweredByText {
    display: none;
    visibility: hidden;
  }

  @media screen and (min-width: 80em) {
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
      border-width: 0;
      border-bottom: 1px solid var(--yes-green-500);
      color: var(--yes-green-500);
    }
  }

  #CybotCookiebotDialogHeader {
    align-items: center;
    border-bottom: transparent;
    display: flex;
    padding: 1em;
    width: 100%;
  }

  #CybotCookiebotDialog .CybotCookiebotScrollContainer {
    border-bottom: transparent;
    min-height: 20vh;
    padding: 0.375em;
    position: relative;
  }

  #CybotCookiebotDialogHeader {
    display: none;
  }

  #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
    font-size: 1rem;
    font-weight: 400;
  }

  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    font-weight: 400;
  }

  #CybotCookiebotDialog h2 {
    font-weight: 400;
  }

  #CybotCookiebotDialog
    .CookieCard
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton,
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab {
    font-weight: 400;
  }
}
