@use './styles/index';
@import '@ng-select/ng-select/themes/default.theme.css';

@font-face {
  font-family: 'Optima';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src:
    url('./assets/fonts/Optima/font.woff2') format('woff2'),
    url('./assets/fonts/Optima/font.woff') format('woff');
}

@font-face {
  font-family: 'Austin';
  src:
    url('./assets/fonts/Austin/Austin-Light-Web.woff2') format('woff2'),
    url('./assets/fonts/Austin/Austin-Light-Web.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Austin';
  src:
    url('./assets/fonts/Austin/Austin-Light-Italic-Web.woff2') format('woff2'),
    url('./assets/fonts/Austin/Austin-Light-Italic-Web.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Grotesk';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('./assets/fonts/Grotesk/BDOGrotesk-VF.woff2') format('woff2');
}

a[routerlink] {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  gap: var(--yes-space-040);
}

dialog {
  position: relative;
  border: 0;

  &::backdrop {
    background: #000;
    opacity: 0.4;
  }
}

.mobile-only {
  @media (min-width: 64em) {
    display: none !important;
  }
}

.desktop-only {
  @media (max-width: 63.99999999em) {
    display: none !important;
  }
}
