:root {
  --yes-space-020: 2px;
  --yes-space-040: 4px;
  --yes-space-060: 6px;
  --yes-space-080: 8px;
  --yes-space-120: 12px;
  --yes-space-140: 14px;
  --yes-space-160: 16px;
  --yes-space-200: 20px;
  --yes-space-240: 24px;
  --yes-space-320: 32px;
  --yes-space-360: 36px;
  --yes-space-380: 38px;
  --yes-space-400: 40px;
  --yes-space-460: 46px;
  --yes-space-480: 48px;
  --yes-space-500: 50px;
  --yes-space-560: 56px;
  --yes-space-600: 60px;
  --yes-space-640: 64px;
  --yes-space-800: 80px;
  --yes-space-960: 96px;
  --yes-space-1120: 112px;
  --yes-space-1128: 128px;
  --yes-space-1440: 144px;
  --yes-space-1600: 160px;
}
