:root {
  --yes-font-primary: 'Optima', Times, 'Times New Roman', serif;
  --yes-font-secondary: 'Austin', sans-serif;
  --yes-font-tertiary: 'Grotesk', sans-serif;
}

%yes-typography-primary-font {
  font-family: var(--yes-font-primary);
  font-style: normal;
  font-weight: 400;
  color: var(--yes-grey-700);
}

%yes-typography-secondary-font {
  font-family: var(--yes-font-secondary);
  font-style: normal;
  font-weight: 300;
  color: var(--yes-grey-700);
}

%yes-typography-heading {
  @extend %yes-typography-secondary-font;
  letter-spacing: -0.02em;
}

%yes-typography-heading-1 {
  @extend %yes-typography-heading;
  font-size: 2.438rem;
  line-height: 1.05;

  @media screen and (min-width: 980px) {
    font-size: 3.25rem;
    line-height: 1.1;
  }
}

%yes-typography-heading-2 {
  @extend %yes-typography-heading;
  font-size: 2.125rem;
  line-height: 1.15;

  @media screen and (min-width: 980px) {
    font-size: 2.813rem;
    line-height: 1.2;
  }
}

%yes-typography-heading-3 {
  @extend %yes-typography-heading;
  font-size: 1.813rem;
  line-height: 1.15;

  @media screen and (min-width: 980px) {
    font-size: 2.25rem;
    line-height: 1.2;
  }
}

%yes-typography-heading-4 {
  @extend %yes-typography-heading;
  font-size: 1.563rem;
  line-height: 1.2;

  @media screen and (min-width: 980px) {
    font-size: 2.125rem;
  }
}

%yes-typography-heading-5 {
  @extend %yes-typography-heading;
  font-size: 1.375rem;
  line-height: 1.2;

  @media screen and (min-width: 980px) {
    font-size: 1.563rem;
  }
}

%yes-typography-heading-6 {
  @extend %yes-typography-heading;
  font-size: 1.188rem;
  line-height: 1.2;

  @media screen and (min-width: 980px) {
    font-size: 1.375rem;
  }
}

%yes-typography-body {
  @extend %yes-typography-primary-font;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: 0;

  @media screen and (min-width: 980px) {
    line-height: 1.5;
    letter-spacing: -0.01em;
  }
}

%yes-typography-body-small {
  @extend %yes-typography-primary-font;
  font-size: 0.875rem;
  line-height: 1.45;
}

%yes-typography-caption {
  @extend %yes-typography-primary-font;
  font-size: 0.75rem;
  line-height: 1.45;
}

%yes-typography-display-1 {
  @extend %yes-typography-secondary-font;
  line-height: 1.05;
  letter-spacing: -0.02em;
  font-size: 7.5rem;
}

%yes-typography-display-2 {
  @extend %yes-typography-secondary-font;
  line-height: 1.05;
  letter-spacing: -0.02em;
  font-size: 6rem;
}

%yes-typography-display-3 {
  @extend %yes-typography-secondary-font;
  line-height: 1.05;
  letter-spacing: -0.02em;
  font-size: 4.75rem;
}

%yes-typography-display-4 {
  @extend %yes-typography-secondary-font;
  line-height: 1.05;
  letter-spacing: -0.02em;
  font-size: 3.813rem;
}

.yes-typography-primary-font {
  @extend %yes-typography-primary-font;
}

.yes-typography-secondary-font {
  @extend %yes-typography-secondary-font;
}

.yes-typography-body {
  @extend %yes-typography-body;
}

.yes-typography-body-small {
  @extend %yes-typography-body-small;
}

.yes-typography-caption {
  @extend %yes-typography-caption;
}

.yes-typography-display-1 {
  @extend %yes-typography-display-1;
}

.yes-typography-display-2 {
  @extend %yes-typography-display-2;
}

.yes-typography-display-3 {
  @extend %yes-typography-display-3;
}

.yes-typography-display-4 {
  @extend %yes-typography-display-4;
}

h1,
.yes-typography-heading-1 {
  @extend %yes-typography-heading-1;
}

h2,
.yes-typography-heading-2 {
  @extend %yes-typography-heading-2;
}

h3,
.yes-typography-heading-3 {
  @extend %yes-typography-heading-3;
}

h4,
.yes-typography-heading-4 {
  @extend %yes-typography-heading-4;
}

h5,
.yes-typography-heading-5 {
  @extend %yes-typography-heading-5;
}

h6,
.yes-typography-heading-6 {
  @extend %yes-typography-heading-6;
}
